.container {
  display: flex;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-height: 400px;
}

/* Sidebar Menu */
.sidebar {
  padding: 12px;
  flex: 0.3;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .sidebar {
    flex: 1;
  }
}

.menuItem {
  text-align: left;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 500;
  color: #404d56;
  font-family: 'Roboto';
  padding: 12px;
  transition: background 0.3s ease, color 0.3s ease;
}

.menuItem:hover {
  background: #e6e6e6;
}

.active {
  background: #36434b !important;
  color: white !important;
}

.border {
  width: 2px;
  background: #eee;
}

@media screen and (max-width: 768px) {
  .border {
    height: 2px;
    width: 100%;
  }
}

/* Content Section */
.content {
  flex: 1;
  padding: 12px;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    border-radius: 0px;
    box-shadow: none;
  }

  .menuItem {
    flex: 1;
  }
}
