.modalContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.closeIcon {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: #404d56;
  color: #fff;
}

.closeIcon:hover {
  background: #36434b !important;
}

.modalContent {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  cursor: pointer;
  z-index: 1000;
}

/* Left & Right Navigation */
.navIcon:first-child {
  left: 10px;
}

.navIcon:last-child {
  right: 10px;
}

/* Media Wrapper */
.mediaWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullImage,
.fullVideo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.description {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #36434b;
  word-break: break-word;
  margin-bottom: 16px;
  padding: 0px 12px;
}
.description p {
  margin: 0;
}

.description a {
  text-decoration: none !important;
  color: #0475f6 !important;
  font-weight: 500;
}

.border {
  width: 1px;
  background-color: #f0f0f0;
}

.commentArea {
  flex: 0.5;
  height: 100%;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.totalCommentsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0px 12px 12px 12px;
  border-bottom: 1px solid #f0f0f0;
}

.totalComments {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0%;
  color: #777777;
  display: flex;
  align-items: center;
  gap: 4px;
}

@media screen and (max-width: 768px) {
  .cropperWrapper {
    height: 200px;
  }
  .navIcon {
    display: none;
  }
  .modalContent {
    height: 500px;
  }
  .modalContainer {
    flex-direction: column;
  }
  .border {
    width: 100%;
    height: 1px;
  }
  .description {
    padding: 0px 8px;
  }
}

@media screen and (max-width: 480px) {
  .cropperWrapper {
    height: 150px;
  }
  .modalContent {
    height: 400px;
  }
  .imageCaption {
    bottom: 30px;
  }
  .navIcon {
    display: none;
  }
  .modalContainer {
    flex-direction: column;
  }
  .border {
    width: 100%;
    height: 1px;
  }
  .footerComment {
    padding: 12px 8px;
  }
  .avatar {
    width: 30px !important;
    height: 30px !important;
  }
  .inputContainer {
    padding: 8px;
  }
  .totalComments {
    font-size: 14px;
  }
  .commentContainer {
    padding: 8px;
  }
  .comment {
    padding: 4px 8px;
  }
  .userName {
    font-size: 12px;
  }
  .commentText {
    font-size: 12px;
  }
  .commentFooterReplies {
    font-size: 12px;
  }
  .date {
    font-size: 10px;
  }
}
