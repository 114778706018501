.modalContent {
  position: relative;
  background-color: #f2f4f7;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Navigation Icons */
.navIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  cursor: pointer;
  z-index: 1000;
}

.eventYear {
  color: #009017;
  font-family: 'Roboto' Condensed;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  margin-bottom: 16px;
}

.event-wrapper {
  min-height: 500px;
  width: 300px;
}

/* Left & Right Navigation */
.navIcon:first-child {
  left: 10px;
}

.navIcon:last-child {
  right: 10px;
}

.mediaWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.fullVideo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
  border-top: 1px solid #e1e1e1;
}

.cropperWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
