.preview {
  margin-top: 16px;
  background: #f8f8f8;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  margin-bottom: 12px;
}

.previewImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.previewText {
  padding: 12px;
}

.siteName {
  font-size: 14px;
  font-weight: 500;
  color: gray;
  margin-bottom: 8px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #080809;
}
