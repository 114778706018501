.family-container {
  background: #fff;
  margin-bottom: 16px;
  border-radius: 10px;
  box-shadow: 1px 4px 10px 3px rgba(0, 0, 0, 0.05);
  padding: 30px 24px;
}

.family-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.family-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
}

.family-header > img {
  height: 100px;
  width: 100px;
}

.family-name {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 8px;
  text-transform: uppercase;
  hyphens: auto;
  -webkit-hyphens: auto; /* For Safari */
  -moz-hyphens: auto; /* For Firefox */
  -ms-hyphens: auto; /* For Internet Explorer */
  word-wrap: break-word;
}

.family-member {
  color: #999;
  font-family: 'Roboto';
  font-size: 16px;
}

.relation {
  background: #fcf3dc;
  padding: 8px;
  color: #999;
  font-family: 'Roboto';
  font-size: 16px;
}

.owner-details {
  display: flex;
  gap: 25px;
  align-items: center;
}

.owner-wrapper {
  display: flex;
  flex-direction: column;
}

.owner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  flex: 1;
}

.owner-name {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  line-height: 129.688%;
  word-break: break-word;
  word-break: break-word;
}

.grave {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 129.688%;
}

.members-btn {
  border-radius: 8px;
  border: 1px solid #19262d;
  padding: 12px;
  cursor: pointer;
  background: #fff;
  color: #19262d;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 170%;
}

.upGrade-button {
  cursor: pointer;
  color: #fbfbfc;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 170%;
  border-radius: 10px;
  background: #404d56;
  border: none;
  padding: 12px;
}

.family-member-heading {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  margin: 16px 0px;
}

.subscription-members {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

@media screen and (max-width: 1400px) {
  .family-header {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1000px) {
  .subscription-members {
    grid-template-columns: repeat(1, 1fr);
  }
}
