.container {
  background-color: #ffff;
  min-height: 400px;
  padding: 12px;
}

.lifeEventsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
  gap: 12px;
}

h3 {
  margin: 0;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

@media screen and (min-width: 768px) {
  .container {
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}
