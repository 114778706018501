.year-btn,
.month-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;
  width: max-content;
}

.year-btn > button,
.month-btn > button {
  font-family: 'Roboto' Condensed;
  font-size: 18px;
  font-weight: 400;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.event-wrapper {
  min-height: 500px;
}

.container-year {
  display: flex;
  gap: 8px;
  flex-direction: row;
  margin-bottom: 16px;
  transition: all ease 0.3s;
}

.container-month {
  display: flex;
  gap: 16px;
  flex-direction: row;
  margin-bottom: 16px;
  transition: all ease 0.3s;
}

.side-container-year,
.side-container-month {
  display: flex;
  align-items: center;
  margin-left: 26px;
  transition: all ease 0.3s;
}

.line-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.line {
  height: 100%;
  width: 1px;
  background-color: #9aa8b2;
}

.dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #9aa8b2;
}

@media screen and (max-width: 480px) {
  .side-container-year {
    display: none;
  }
  .side-container-month {
    margin-left: 12px;
  }
}
