.ant-tabs-tab {
  color: #363636 !important;
}

.ant-tabs-tab-active {
  color: #363636 !important;
}
.tab-buttons {
  display: flex;
  width: 100%;
}

.tab-button {
  white-space: nowrap;
  height: 60px;
  background: none;
  border: none;
  font-weight: 600;
  font-size: 16px;
  color: #65686c;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  padding: 0px 16px;
}

.tab-more-button {
  white-space: nowrap;
  background: none;
  border: none;
  font-weight: 600;
  color: #65686c;
  cursor: pointer;
  position: relative;
  padding: 8px 16px;
}

.tab-button.active {
  color: #404d56;
}

.tab-button.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #404d56;
}

.connect-btn-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.connect-btn {
  background: #404d56;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.connect-btn:hover {
  background: #404d56;
}

.tab-more-button-container {
  display: flex;
  align-items: center;
}
