.deceased-page-container {
  max-width: 100%;
  overflow: auto;
  background: #f2f5f8;
  min-height: calc(100vh - 90px);
  padding-bottom: 12px;
}

.topSection {
  background-color: #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.backgroundImage {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: top;
  border-radius: 0px 0px 10px 10px;
}

@media screen and (max-width: 1200px) {
  .backgroundImage {
    border-radius: 0;
  }
}

@media screen and (max-width: 550px) {
  .backgroundImage {
    height: 200px;
  }
}

.deceased-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

@media screen and (max-width: 768px) {
  .deceased-page {
    padding: 16px 0px 0px 0px;
  }
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.deceased-virtue {
  border-radius: 10px;
  border: 1px solid #fff;
  background: #fff;
  margin-bottom: 38px;
  padding: 24px;
}

.menu-tabs-deceased {
  background: #fff;
}

.about-container {
  display: flex;
  gap: 42px;
}

@media screen and (max-width: 1020px) {
  .deceased-virtue {
    border-radius: 0;
  }
}
