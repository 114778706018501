.tab-container {
  display: flex;
  gap: 24px;
  margin-bottom: 22px;
}

.grave-info-location_span {
  margin-top: 25px;
  font-size: 18px;
  line-height: 16px;
  color: #999999;
}
.other-relation-text {
  font-size: 16px;
  color: #36434b;
}

.message-info {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-weight: 400;
  margin-bottom: 12px;
}

.choose-relation-text {
  font-size: 20px;
  font-family: 'Roboto';
  color: #19262d;
  margin-bottom: 16px;
}

.grave-info-location {
  color: #999999;
  margin: 15px 0px;
}

.relation-list-autocomplete {
  background: #f5f5f5;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 22px;
}

.accept-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 36px;
}

.label {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #404d56;
}

.accept-container > input[type='checkbox'] {
  background-color: #fff;
  margin: 0;
  color: #fea65a;
  width: 20px;
  height: 20px;
  border: 1px solid #404d5666;
  cursor: pointer;
}

.submit-button-wrapper {
  display: flex;
  text-align: center;
  justify-content: flex-end;
  gap: 12px;
}

.grave-set-relation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-field-relation {
  margin-bottom: 16px;
}

.relation-alert-pending {
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #ddffde;
  color: #36434b;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

.death-age {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}

.grave-image-container {
  width: 350px;
  padding-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Avatar {
  height: 200px !important;
  width: 200px !important;
  border-radius: 10px !important;
  margin-bottom: 8px;
}

.grave-name-relation {
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 500;
  color: #36434b;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.tab-content_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.upload-certificate-placeholder_span {
  color: rgba(0, 0, 0, 0.54);
  padding: 13px;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.set-relation-and-claim-modal_display_image_wrapper {
  display: grid;
}

.relation-desc-subheader {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fea65a;
}

.choose-relation {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 12px;
}

.active-button {
  width: 100%;
  text-align: center;
  padding: 16px;
  border: none;
  background: #36434b;
  cursor: pointer;
  color: white;
  border-radius: 4px;
}

.inactive-button {
  width: 100%;
  text-align: center;
  padding: 16px;
  border: none;
  background: #8080801c;
  cursor: pointer;
  color: #9f9f9f;
  border-radius: 4px;
}

.message-text {
  color: #36434b;
  font-family: 'Roboto';
}

@media (max-width: 600px) {
  .certificate-type-radio-group {
    display: grid !important;
    grid-template-columns: auto !important;
    margin: 20px 5px !important;
  }

  .set-relation-and-claim-modal {
    width: 455px;
    display: contents;
  }
  .claim-grave-upload > div {
    width: 99%;
  }

  .submit-button-wrapper {
    display: flex;
    width: 100%;
    text-align: center;
  }

  .set-relation-or-claim-confirmation_modal > h1 {
    padding: 12px 28px 10px;
    font-size: 25px;
  }

  .set-relation-or-claim-confirmation_modal {
    width: 100%;
    height: 288px;
  }

  .cancel-x-button {
    margin: 5px;
  }

  .tab-container {
    gap: 12px;
  }
}
