.imageContainer {
  position: relative;
  width: 100%;
  height: 230px !important;
  overflow: hidden;
  border-radius: 4px;
}

.gravePhotosHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

h3 {
  margin: 0;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
  border-top: 1px solid #e1e1e1;
}

.cropperWrapper {
  position: relative;
  width: 100%;
  height: 400px;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.651);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.title {
  font-size: 1.3rem;
  font-weight: 500;
  color: #fff;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: white;
  background-color: #ccc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

.closeIcon {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: #404d56;
  color: #fff;
}

.closeIcon:hover {
  background: #36434b !important;
}

.imageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.address {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 19px;
  color: #36434b;
  padding: 0px 12px;
}

.memoryCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.privacyContainer {
  display: flex;
  align-items: center;
  gap: 4px; /* Adjust spacing */
}

.dot {
  font-size: 16px;
  color: #777777;
}

.privacyIcon {
  color: #777777;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.name {
  cursor: pointer;
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-weight: 700;
  font-size: 1rem;
  line-height: 18px;
  letter-spacing: 0%;
}

.name:hover {
  text-decoration: underline;
}
.userInfo {
  display: flex;
  flex-direction: column;
}

.createdAt {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0%;
  color: #777777;
}

.menu {
  display: flex;
  flex-direction: column;
  width: 150px;
}

.description {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #36434b;
  word-break: break-word;
  padding: 0px 12px 12px 12px;
}

.totalCommentsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0px 12px 12px 12px;
  border-bottom: 1px solid #f0f0f0;
}

/* Modal Content */
.modalContent {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageCaption {
  position: absolute;
  bottom: 50px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
}

/* Navigation Icons */
.navIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  cursor: pointer;
  z-index: 1000;
}

/* Left & Right Navigation */
.navIcon:first-child {
  left: 10px;
}

.navIcon:last-child {
  right: 10px;
}

/* Media Wrapper */
.mediaWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullImage,
.fullVideo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.modalContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.commentArea {
  flex: 0.5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.border {
  width: 1px;
  background-color: #f0f0f0;
}

.footerComment {
  display: flex;
  gap: 8px;
  padding: 12px 16px;
}

.avatar {
  width: 36px !important;
  height: 36px !important;
  align-self: flex-start;
}

.inputContainer {
  flex: 1;
  position: relative;
  border-radius: 10px;
  padding: 12px;
  background: #f5f5f5;
  cursor: text;
}

.sendIcon {
  position: absolute;
  right: 6px;
  bottom: 6px;
  background: #404d56;
}

.totalComments {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0%;
  color: #777777;
  display: flex;
  align-items: center;
  gap: 4px;
}

.commentContainer {
  display: flex;
  gap: 8px;
  padding: 12px;
}

.comment {
  background: #f5f5f5;
  padding: 6px 12px;
  border-radius: 10px;
}

.userName {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  color: #36434b;
}

.commentText {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  color: #36434b;
  word-break: break-word;
}

.commentFooterReplies {
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.date {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  color: #777777;
}

@media screen and (min-width: 769px) {
  .allComments {
    flex: 1;
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .cropperWrapper {
    height: 200px;
  }
  .navIcon {
    display: none;
  }
  .modalContent {
    height: 500px;
  }
  .modalContainer {
    flex-direction: column;
  }
  .border {
    width: 100%;
    height: 1px;
  }
}

@media screen and (max-width: 480px) {
  .cropperWrapper {
    height: 150px;
  }
  .modalContent {
    height: 400px;
  }
  .imageCaption {
    bottom: 30px;
  }
  .navIcon {
    display: none;
  }
  .modalContainer {
    flex-direction: column;
  }
  .border {
    width: 100%;
    height: 1px;
  }
  .footerComment {
    padding: 12px 8px;
  }
  .avatar {
    width: 30px !important;
    height: 30px !important;
  }
  .inputContainer {
    padding: 8px;
  }
  .totalComments {
    font-size: 14px;
  }
  .commentContainer {
    padding: 8px;
  }
  .comment {
    padding: 4px 8px;
  }
  .userName {
    font-size: 12px;
  }
  .commentText {
    font-size: 12px;
  }
  .commentFooterReplies {
    font-size: 12px;
  }
  .date {
    font-size: 10px;
  }
}
