.emptyMemory {
  background: #fff;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  flex: 1;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .emptyMemory,
  .loading {
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}
