.heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  margin-bottom: 12px;
}

@media screen and (max-width: 750px) {
  .heading {
    font-size: 24px;
  }
}

.empty-message {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 167.19%;
  color: #999999;
}

.payment-value-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.payment-value-container>img {
  width: 36px;
}