.subscription-heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  margin-bottom: 24px;
  word-break: break-word;
  max-width: 100%;
}

@media screen and (max-width: 750px) {
  .subscription-heading {
    font-size: 24px;
  }
}

.subscription-status {
  padding: 8px 12px;
  background-color: #108ee9;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 8px 0px;
}

.subscription-card {
  padding: 24px;
  border-radius: 10px;
  box-shadow: 1px 4px 10px 3px rgba(0, 0, 0, 0.05);
  background: #fff;
  margin-bottom: 44px;
  position: relative;
}

.subscription-name {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  margin-bottom: 14px;
}

.subscription-detail {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 52px;
}

.subscription-update {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
}

.plan-name-heading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 24px;
}

.price {
  color: #565560;
  font-family: 'Roboto';
  font-size: 23px;
  font-weight: 400;
}

.price>span {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 32px;
  font-weight: 500;
}

.members {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 500;
}

.upGrade-button {
  cursor: pointer;
  color: #fbfbfc;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 600;
  line-height: 170%;
  text-transform: uppercase;
  border-radius: 10px;
  background: #404d56;
  border: none;
  padding: 17px 56px;
}

.paid-subscription-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.paid-subscription-name {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  word-break: break-word;
  max-width: 100%;
}

.paid-subscription-detail {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.payment-details,
.billing-information {
  flex: 1;
}

.plans-heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
}

.payment-heading,
.billing-heading {
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 28px;
}

.loading-message {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-top: 12px;
}

.loading-container {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.detail {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.title {
  color: #9aa8b2;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  flex: 1;
}

.value {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  flex: 1;
}

.change-card {
  cursor: pointer;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 600;
  line-height: 170%;
  text-decoration-line: underline;
  margin-bottom: 20px;
}

.due-date {
  color: #985000;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.next-payment {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.edit {
  cursor: pointer;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 600;
  line-height: 170%;
  text-decoration-line: underline;
}

.btn-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.change-plan {
  cursor: pointer;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #f2f5f8;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  transition: ease 0.3s;
}

.change-plan:hover {
  background-color: #cecfd0;
}

.cancel-border-container {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #f23131;
  background: #fff;
  margin-bottom: 44px;
  position: relative;
}

.cancel-subscription {
  cursor: pointer;
  padding: 11px 24px;
  border: 1px solid #404d56;
  border-radius: 6px;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.change-payment {
  cursor: pointer;
  padding: 17px 22px;
  background: #fff;
  border: none;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 600;
  line-height: 170%;
}

.active-memberships-header {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 12px;
  word-break: break-word;
  max-width: 100%;
}

.active-memberships-header>span {
  color: #9aa8b2;
  font-family: 'Roboto' Condensed;
  font-size: 25px;
  font-weight: 500;
}

.membership-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}

.empty-gift-memberships {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 47.5px;
  margin-bottom: 42px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-bottom: 42px;
}

.plan-link {
  text-decoration: none;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 47.5px;
}

@media screen and (max-width: 1100px) {
  .paid-subscription-header {
    flex-direction: column;
    align-items: start;
    gap: 24px;
    margin-bottom: 24px;
  }

  .btn-container {
    flex-direction: column;
    align-items: normal;
  }
}

@media screen and (max-width: 765px) {

  .payment-heading,
  .billing-heading {
    color: #19262d;
    font-family: 'Roboto' Condensed;
    font-size: 25px;
    font-weight: 500;
  }
}

@media screen and (max-width: 480px) {
  .cancel-subscription {
    width: 100%;
  }

  .change-payment {
    width: 100%;
  }
}

.subscription-alert {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
}

.subscription-alert .alert-title {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}

.subscription-alert .alert-message {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
}