.notifications-page {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.notifications-page_content {
  display: flex;
  flex-direction: column;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.notifications-page_content-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.notifications-page_content-header_header {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}

@media screen and (max-width: 750px) {
  .notifications-page_content-header_header {
    font-size: 24px;
  }
}

.notifications-page_orange-line {
  width: 173px;
  border: 1px solid #fea65a;
  margin-top: 10px;
  margin-bottom: 20px;
}

.notifications-page_content-header_subheader {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 167.19%;
  color: #999999;
  margin-bottom: 16px;
}

.notification-checkboxes {
  display: flex;
  align-items: center;
  gap: 30px;
}

.notifications-page_checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notifications-page_checkbox-checkbox {
  opacity: 0.4;
  border: 1.29155px solid #999999;
}

.notifications-page_checkbox-label {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #36434b;
  margin-left: 6px;
}

.notifications-page_content-main {
  display: flex;
  flex-direction: column;
  border: 1px solid #d6d6d6;
  padding: 25px;
  margin-bottom: 30px;
}

.notifications-page_content-main_header {
  font-family: Roboto;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #36434b;
  margin-bottom: 12px;
}

.notifications-page_content-main_subheader {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #999999;
  margin-bottom: 25px;
}

.notifications-page_content-general {
  display: flex;
  flex-direction: column;
}

.checkbox-icon {
  width: 19px;
  height: 19px;
  border: 1px solid #c0bfbf;
  border-radius: 3px;
}

.checkbox-checkedIcon {
  width: 19px;
  height: 19px;
  border: 1px solid #c0bfbf;
  background-color: #fff;
  border-radius: 3px;
  background-image: url('../../../img/check.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 12px;
}

.notification_content-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.notification-button_save {
  font-family: 'Minion Pro';
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #fea65a;
  border-radius: 10px;
  height: 40px;
  width: 150px;
  transition-duration: 0.4s;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.notification-button_save:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.notification-button_cancel {
  font-family: 'Minion Pro';
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 40px;
  width: 150px;
  color: #fea65a;
  border: 1px solid #fea65a;
  border-radius: 10px;
  transition-duration: 0.4s;
  margin-right: 30px;
  background: none;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .notifications-page {
    width: auto;
    max-width: 898px;
  }
}

@media (max-width: 765px) {
  /* .notifications-page_content {
    padding: 20px 10px;
  } */

  .notification_content-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notifications-page_content-main_header {
    font-size: 16px;
    line-height: 19px;
  }

  .notification-checkboxes {
    flex-wrap: wrap;
    gap: 8px;
  }

  .notifications-page_content-header_subheader {
    font-size: 14px;
    line-height: 24px;
  }

  .notifications-page_checkbox-label {
    font-size: 14px;
    line-height: 16px;
  }

  .notification-button_save,
  .notification-button_cancel {
    font-size: 16px;
    line-height: 15px;
  }
}