.container {
  background-color: #ffff;
  min-height: 400px;
  padding: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
}

h3 {
  margin: 0;
}

.editButton {
  color: #404d56;
}

.editButton:hover {
  color: #36434b;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.userCard {
  display: flex;
  align-items: center;
}

.userInfo {
  flex: 1;
  margin-left: 10px;
}

.userHeader {
  display: flex;
  align-items: center;
}

.userName {
  font-size: 14px;
  font-weight: 800;
  color: #404d56;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s;
  cursor: pointer;
}

.userName:hover {
  border-color: #404d56;
}

.adminTag {
  margin-left: 5px;
  font-size: 12px;
}

.relation {
  font-size: 12px;
  color: #777;
}

.moreButton {
  font-size: 18px;
}

.popoverContent {
  display: flex;
  flex-direction: column;
}

.popoverButton {
  justify-content: flex-start;
  width: 100%;
}

.userRelation {
  display: flex;
  gap: 4px;
  align-items: center;
}

.invitationForm {
  margin-top: 16px;
  border-bottom: 1px solid #f0f0f0;
}

@media screen and (min-width: 768px) {
  .container {
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}
