.container {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.sub-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 100%;
}

.calender {
  background-color: #f2f4f7;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.title {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 500;
}

.date {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 1rem;
}

.timer-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.edit-btn-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.remaining-timer {
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 400;
  color: #985000;
}

.menu {
  display: flex;
  flex-direction: column;
  width: 150px;
}

.anniversaryTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .container {
    align-items: flex-start;
  }
  .title-container {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
