.set-relation-info {
  border-radius: 10px;
  background: #fff;
  padding: 16px;
}

.topSection {
  background-color: #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.deceased-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

@media screen and (max-width: 768px) {
  .deceased-page {
    padding: 16px 0px 0px 0px;
  }
}
