.cropper {
  width: 100% !important;
  overflow: hidden;
  margin-bottom: 24px;
}

.cropper > img {
  width: 100% !important;
  opacity: 1 !important;
}

.camera-icon {
  font-size: 25px !important;
  color: #404d56;
  border: 1px solid #0000001a;
  background: #fff;
  padding: 4px;
  border-radius: 50%;
}

.container {
  position: absolute;
  top: 100px;
  right: -4px;
}

.btn {
  display: flex;
  justify-content: flex-end;
}

.user-avatar {
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 66%;
  border: 5px solid lightgrey;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.avatar {
  height: 150px !important;
  width: 150px !important;
}

@media screen and (min-width: 821px) {
  .user-avatar {
    bottom: 19%;
    left: 110px;
  }

  .container {
    top: 136px;
    right: 6px;
  }

  .camera-icon {
    font-size: 26px !important;
    padding: 6px;
  }

  .avatar {
    height: 180px !important;
    width: 180px !important;
  }
}
