.footerComment {
  display: flex;
  gap: 8px;
  padding: 12px 16px;
}

.avatar {
  width: 36px !important;
  height: 36px !important;
  align-self: flex-start;
}

.sendIcon {
  position: absolute;
  right: 6px;
  bottom: 6px;
  background: #404d56;
}

.cancel {
  font-size: 12px;
  font-family: 'Roboto';
  color: #777777;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  transition: all ease 0.3s;
  padding-bottom: 2px;
  cursor: pointer;
  margin: 8px 0px;
  width: max-content;
}

.cancel:hover {
  border-color: #777777;
}

.commentContainer {
  display: flex;
  gap: 4px;
  padding: 12px 12px 6px 12px;
}

.comment {
  background: #f5f5f5;
  padding: 6px 12px;
  border-radius: 10px;
}

.userName {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  color: #36434b;
}

.commentText {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  color: #36434b;
  word-break: break-word;
}

.commentFooterReplies {
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.date {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  color: #777777;
}

.inputContainer {
  flex: 1;
  position: relative;
  border-radius: 10px;
  padding: 12px;
  background: #f5f5f5;
  cursor: text;
}

.menu {
  display: flex;
  flex-direction: column;
  width: 150px;
}

@media screen and (min-width: 769px) {
  .allComments {
    flex: 1;
    overflow-y: auto;
  }
}
