.closeIcon {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background: #404d56;
  color: #fff;
}

.closeIcon:hover {
  background: #36434b !important;
}

.navButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 0px;
  border-bottom: 1px solid #0000001a;
}

.modalContainer {
  display: flex;
  gap: 12px;
}

.eventYear {
  color: #009017;
  font-family: 'Roboto' Condensed;
  font-weight: 400;
  font-size: 22px;
  line-height: 100%;
  letter-spacing: 0%;
  width: max-content;
  background: #e4fde6;
  padding: 8px 18px;
  border-radius: 25px;
  height: max-content;
  flex: 0 0 auto; /* Do not stretch */
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.rightContent {
  display: flex;
  flex-direction: column;
  flex: 1; /* Take up remaining space */
  min-width: 0; /* Prevent overflow issues */
}

.moreEvents {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0%;
  padding: 16px 0px;
  color: #36434b;
}

@media screen and (max-width: 768px) {
  .modalContainer {
    flex-direction: column;
  }
  .eventYear {
    margin-bottom: 12px;
  }
  .moreEvents {
    font-size: 18px;
  }
}
