.createPostHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0px 16px;
  margin-bottom: 12px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.name {
  cursor: pointer;
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-weight: 700;
  font-size: 1rem;
  line-height: 18px;
  letter-spacing: 0%;
}

.name:hover {
  text-decoration: underline;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
  border-top: 1px solid #e1e1e1;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.651);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.modalContent {
  position: relative;
  background-color: #f2f4f7;
  flex: 1;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Navigation Icons */
.navIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  cursor: pointer;
  z-index: 1000;
}

/* Left & Right Navigation */
.navIcon:first-child {
  left: 10px;
}

.navIcon:last-child {
  right: 10px;
}

.mediaWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullImage,
.fullVideo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.setCoverImage {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.cropperWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .navIcon {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .modalContent {
    height: 400px;
  }
  .navIcon {
    display: none;
  }
}
