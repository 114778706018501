.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

h3 {
  margin: 0;
}

.editButton {
  color: #404d56;
}

.editButton:hover {
  color: #36434b;
}

.interestsForm {
  margin-top: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.interestHeader {
  display: flex;
}

.interestInfo {
  flex: 1;
  margin-left: 10px;
}

.interestList {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.moreButton {
  font-size: 18px;
}

.popoverContent {
  display: flex;
  flex-direction: column;
}

.popoverButton {
  justify-content: flex-start;
  width: 100%;
}
