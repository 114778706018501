.date-container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.date {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 600;
  border-right: 2px solid #9aa8b2;
  padding-right: 8px;
  margin-right: 8px;
}

.location {
  color: #9aa8b2;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.title {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.description {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}
