.item {
  display: flex;
  align-items: center;
  gap: 8px;
}
.createPostHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0px 16px;
  margin-bottom: 12px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.name {
  cursor: pointer;
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-weight: 700;
  font-size: 1rem;
  line-height: 18px;
  letter-spacing: 0%;
}

.name:hover {
  text-decoration: underline;
}

.previewWrapper {
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.previewContainer {
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.previewItem {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.mediaWrapper {
  width: 100%;
  aspect-ratio: 2 / 3; /* Ensures consistent height for different aspect ratios */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
}

.media {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images and videos fit nicely within the frame */
  border-radius: 8px;
}

.privacySection {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 6px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
  border-top: 1px solid #e1e1e1;
}

.content {
  padding: 0px 16px;
  margin-bottom: 16px;
}

.previewHeader {
  margin-bottom: 12px;
}

.cropper {
  width: 100% !important;
  overflow: hidden;
}

.cropper > img {
  width: 100% !important;
  opacity: 1 !important;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.651);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.title {
  font-size: 1.3rem;
  font-weight: 500;
  color: #fff;
}

.mentionContainer {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mentionContainer:hover {
  background-color: #f2f4f7;
}

.mentionName {
  font-size: 14px;
  color: #36434b;
  font-weight: 500;
  line-height: 20px;
  font-family: 'Roboto';
}

.mentionRelation {
  font-size: 12px;
  color: #36434b;
  font-family: 'Roboto';
}
