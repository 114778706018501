.container {
  background: #ffffff;
  padding: 12px;
  margin-bottom: 12px;
}

.empty-message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 29.3px;
  color: #36434b;
  width: 100%;
  min-height: 200px;
}

@media screen and (min-width: 768px) {
  .container {
    box-shadow: 1px 3px 20px 0px #0000001a;
    border-radius: 10px;
  }
}
