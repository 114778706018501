.container {
  background-color: #fff;
  padding: 12px;
  height: max-content;
}

@media screen and (min-width: 768px) {
  .container {
    flex: 0.6;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}

.title {
  color: #36434b;
  font-size: 20px;
  font-weight: 700;
  padding: 12px 6px;
}

.infoItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.icon {
  background-color: #f2f4f7;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}

.label,
.value {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 1rem;
}

.label {
  white-space: nowrap;
  font-weight: 500;
}
