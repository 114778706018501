.settings-page-nav {
  padding-inline: 16px;
}

.header-title {
  color: #19262d;
  font-family: 'Poppins';
  font-size: 31.26px;
  font-weight: 500;
  margin-bottom: 24px;
  margin-left: 16px;
}

.back-to-dashboard-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin: 24px 0;
}

.back-to-dashboard {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
}

.settings-page_header-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4px;
  width: 100%;
  background: #e4f3fd;
  border-radius: 10px 10px 0px 0px;
}

.settings-page_header-info-userImage {
  position: relative;
  margin: 20px;
  width: 150px;
  height: 140px;
  border-radius: 19px;
}

.settings-page_header-info_icon {
  position: absolute;
  z-index: 2;
  bottom: -8px;
  right: -10px;
  background: #ffffff;
  padding: 10px;
  border-radius: 20px;
}

.settings-page_header-info_photo {
  margin-bottom: 16px;
  width: 150px;
  height: 140px;
  position: relative;
  border: 1px solid #fff;
  border-radius: 25px;
}

.settings-page_header-info_name {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 4px;
  padding: 0px 14px;
  word-break: break-word;
  word-break: break-word;
}

.settings-page_header-info_date-of-birth {
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.settings-page_header-navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
}

.settings-page_header-navigation_first,
.settings-page_header-navigation_second {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
}

.settings-page_header-navigation_second {
  margin-bottom: 20px;
}

.settings-page_header-navigation_name_first_border,
.settings-page_header-navigation_name_second_border,
.settings-page_header-navigation_name_third_border,
.settings-page_header-navigation_name_forth_border,
.settings-page_header-navigation_name_fifth_border {
  border-bottom: 1px solid #c4c4c4;
  width: calc(100% - 40px);
  position: absolute;
}

.settings-page_header-navigation_name_first_border {
  bottom: 50%;
}

.settings-page_header-navigation_name_second_border {
  bottom: 1px;
}

.settings-page_header-navigation_name_third_border {
  bottom: 126px;
}
.settings-page_header-navigation_name_forth_border {
  bottom: 64px;
}
.settings-page_header-navigation_name_fifth_border {
  bottom: 1px;
}

.settings-page_header-navigation_name {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 700;
  line-height: 18px;
}

.settings-page_header-info_photo.disabled {
  display: none;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  cursor: pointer;
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
  word-break: break-word;
  background-color: #e4f3fd;
  margin-bottom: 4px;
  width: 100%;
  border: none;
  outline: none;
}
.listItem:focus {
  color: #e4f3fd;
  background-color: #19262d;
}
.listItem:last-child {
  border-radius: 0px 0px 10px 10px;
}
.listItemSelected {
  color: #e4f3fd;
  background-color: #19262d !important;
}
@media (max-width: 820px) {
  .listItem {
    padding: 14px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .listItemSelected {
    padding: 14px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 900px) {
  .settings-page-nav {
    width: 350px;
    padding: 0;
  }
  .header-title {
    margin-left: 0px;
  }
}

@media (min-width: 1000px) {
  .settings-page-nav {
    width: 400px;
    padding: 0;
  }
}
