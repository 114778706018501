.container {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 16px;
}

.sparks-list {
  flex: 1;
  /* Existing styles */
  overflow-wrap: anywhere;
  word-break: break-word;
}

.user-container {
  display: none;
}

.sparks-container {
  display: flex;
  /* flex-direction: column-reverse; */
}

@media screen and (min-width: 821px) {
  .user-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .sparks-container {
    gap: 20px;
    flex-direction: row;
  }
  .sticky {
    position: sticky;
    top: 166px;
    height: fit-content;
  }
}

/* New class for fixed popup size */
.popup-container {
  width: 400px; /* Adjust based on your design */
  height: 500px; /* Keep this fixed to prevent dynamic resizing */
  max-width: 90%; /* Ensure responsiveness */
  max-height: 90%; /* Prevent overflow */
  padding: 20px;
  overflow: hidden; /* Prevent content from resizing the popup */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Add word-breaking rule for sparks-container and sparks-list */
.sparks-container, .sparks-list {
  overflow-wrap: anywhere;
  word-wrap: break-word;
}

/* Add media query here */
@media (max-width: 768px) {
  .popup-container {
    width: 90%; /* Adjust width for smaller screens */
    height: auto; /* Let height adapt */
    max-height: 90%; /* Prevent overflowing */
    padding: 10px; /* Reduce padding for smaller viewports */
  }

  .text-area {
    max-height: 150px; /* Limit textarea height for smaller screens */
  }
}
