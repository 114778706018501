.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

h3 {
  margin: 0;
}

.content {
  margin-top: 12px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
  border-top: 1px solid #e1e1e1;
}

.inputItems {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .inputItems {
    gap: 0;
    flex-direction: column;
  }
}
