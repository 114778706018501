.memoryCard {
  background: #fff;
  padding: 16px 0px;
  margin-bottom: 16px;
}

.memoryCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0px 8px;
}

.privacyContainer {
  display: flex;
  align-items: center;
  gap: 4px; /* Adjust spacing */
}

.dot {
  font-size: 16px;
  color: #777777;
}

.privacyIcon {
  color: #777777;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.name {
  cursor: pointer;
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-weight: 700;
  font-size: 1rem;
  line-height: 18px;
  letter-spacing: 0%;
}

.name:hover {
  text-decoration: underline;
}
.userInfo {
  display: flex;
  flex-direction: column;
}

.createdAt {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0%;
  color: #777777;
}

.menu {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.description {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #36434b;
  word-break: break-word;
  margin-bottom: 16px;
  padding: 0px 8px;
}
.description p {
  margin: 0;
}

.description a {
  text-decoration: none !important;
  color: #0475f6 !important;
  font-weight: 500;
}

.seeMoreButton {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #36434b;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0px;
}

/* -------------------------- Gallery styles -----------------------*/

.mediaContainer {
  display: grid;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  gap: 1px;
  margin-bottom: 12px;
}

/* 1 Media - Full Width, 500px Height */
.mediaContainer[data-count='1'] {
  grid-template-columns: 1fr;
  grid-template-rows: 400px;
}

/* 2 Media - Two Columns, Same Height */
.mediaContainer[data-count='2'] {
  grid-template-columns: 1fr;
  grid-template-rows: 180px 180px;
}

/* 3 Media - One Large, Two Small Below */
.mediaContainer[data-count='3'] {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 170px 120px;
}

.mediaContainer[data-count='3'] .mediaItem:nth-child(1) {
  grid-column: span 2;
  height: 170px;
}

.mediaContainer[data-count='3'] .mediaItem:nth-child(2),
.mediaContainer[data-count='3'] .mediaItem:nth-child(3) {
  height: 120px;
}

/* 4 Media - One Large, Three Small Below */
.mediaContainer[data-count='4'] {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 170px 120px;
}

.mediaContainer[data-count='4'] .mediaItem:nth-child(1) {
  grid-column: span 3;
  height: 170px;
}

.mediaContainer[data-count='4'] .mediaItem:nth-child(2),
.mediaContainer[data-count='4'] .mediaItem:nth-child(3),
.mediaContainer[data-count='4'] .mediaItem:nth-child(4) {
  height: 120px;
}

/* 5 Media - First Two in One Row, Next Three Below */
.mediaContainer[data-count='5'] {
  grid-template-columns: repeat(4 1fr); /* First row: Two items take equal width */
  grid-template-rows: 170px 120px; /* First row taller */
}

/* First two images in the first row (each takes 1.5 columns) */
.mediaContainer[data-count='5'] .mediaItem:nth-child(1) {
  grid-column: span 4;
  height: 170px;
}

/* Remaining three images in the second row (each takes 1 column) */
.mediaContainer[data-count='5'] .mediaItem:nth-child(2),
.mediaContainer[data-count='5'] .mediaItem:nth-child(3),
.mediaContainer[data-count='5'] .mediaItem:nth-child(4),
.mediaContainer[data-count='5'] .mediaItem:nth-child(5) {
  height: 120px;
}

/* Media Item Styling */
.mediaItem {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f2f4f7;
  cursor: pointer;
}

.mediaItem img,
.mediaItem video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

/* Last Item Overlay (for +X) */
.mediaItem.more {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mediaItem.more .overlay {
  position: absolute;
  color: white;
  font-size: 24px;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
}

.videoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoWrapper video {
  width: 100%;
  display: block;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

/*---------------------------------------- Media modal styles ----------------------------- */

/* Modal Content */
.modalContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageCaption {
  position: absolute;
  bottom: 50px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
}

/* Navigation Icons */
.navIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  cursor: pointer;
  z-index: 1000;
  display: none;
}

/* Left & Right Navigation */
.navIcon:first-child {
  left: 10px;
}

.navIcon:last-child {
  right: 10px;
}

/* Media Wrapper */
.mediaWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullImage,
.fullVideo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* ------------------------------------ Footer comments styles ---------------------- */

.commentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  padding: 6px;
  color: hsl(0deg 0% 50%);
  background: transparent;
  transition: 0.3s ease;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  justify-content: center;
  text-transform: capitalize;
}

.comment:hover .reactions {
  display: block;
}

.comment:hover {
  color: #36434b;
  background: #eee;
}

.shareApps {
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 12px;
  min-height: 100px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.shareApps::-webkit-scrollbar {
  display: none;
}

.share {
  border-radius: 6px;
  padding: 8px 12px;
  background: #f2f4f7;
  display: flex;
  align-items: center;
  gap: 8px;
}

.link {
  color: #36434b;
  font-weight: 600;
  font-size: 15px;
  flex: 1;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.link::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 821px) {
  .comment {
    padding: 8px;
  }
}

@media screen and (min-width: 768px) {
  .memoryCardHeader {
    padding: 0px 12px;
  }
  .memoryCard {
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .navIcon {
    display: inline;
  }
  .videoWrapper video::-webkit-media-controls-overlay-play-button {
    transform: scale(0.5); /* Shrinks the button */
  }
  .mediaContainer[data-count='1'] {
    grid-template-rows: 400px;
  }

  /* 2 Media - Two Columns, Same Height */
  .mediaContainer[data-count='2'] {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px;
  }
  .mediaContainer[data-count='3'] {
    grid-template-rows: 300px 200px;
  }

  .mediaContainer[data-count='3'] .mediaItem:nth-child(1) {
    grid-column: span 2;
    height: 300px;
  }

  .mediaContainer[data-count='3'] .mediaItem:nth-child(2),
  .mediaContainer[data-count='3'] .mediaItem:nth-child(3) {
    height: 200px;
  }

  .mediaContainer[data-count='4'] {
    grid-template-rows: 300px 200px;
  }

  .mediaContainer[data-count='4'] .mediaItem:nth-child(1) {
    grid-column: span 3;
    height: 300px;
  }

  .mediaContainer[data-count='4'] .mediaItem:nth-child(2),
  .mediaContainer[data-count='4'] .mediaItem:nth-child(3),
  .mediaContainer[data-count='4'] .mediaItem:nth-child(4) {
    height: 200px;
  }
  .mediaContainer[data-count='5'] {
    grid-template-rows: 300px 200px; /* First row taller */
  }

  .mediaContainer[data-count='5'] .mediaItem:nth-child(1) {
    grid-column: span 4;
    height: 300px;
  }

  /* Remaining three images in the second row (each takes 1 column) */
  .mediaContainer[data-count='5'] .mediaItem:nth-child(2),
  .mediaContainer[data-count='5'] .mediaItem:nth-child(3),
  .mediaContainer[data-count='5'] .mediaItem:nth-child(4),
  .mediaContainer[data-count='5'] .mediaItem:nth-child(5) {
    height: 200px;
  }

  .description {
    padding: 0px 12px;
  }
}
