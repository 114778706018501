.settings-personal-information_border {
  background-color: #fff;
  border: 1px solid #dfdfdf;
}

.settings-personal-information_content-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 24px;
  align-items: center;
}

.settings-user-information-container {
  padding: 24px;
  box-shadow: 1px 4px 10px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.settings-personal-information_content-header_title {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  word-break: break-word;
  max-width: 100%;
}

@media screen and (max-width: 750px) {
  .settings-personal-information_content-header_title {
    font-size: 24px;
  }
}

.settings-personal-information_content-header-edit {
  background: #fea65a;
  border-radius: 6px;
  padding: 8px 14px;
  display: flex;
  width: max-content;
  flex-direction: row;
  cursor: pointer;
}

.settings-personal-information_content-header-edit>span {
  margin-left: 10px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.settings-personal-information_orange-line {
  width: 173px;
  border: 1px solid #fea65a;
  margin-bottom: 32px;
}

.settings-user-information {
  display: flex;
  margin-bottom: 18px;
}

.settings-labels {
  text-align: start;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  width: 160px;
}

.user-info {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #36434b;
  flex: 1;
  word-break: break-word;
}

.settings-personal-information_content-inputs_first {
  display: flex;
  flex-direction: row;
}

.settings-personal-information_content-inputs_first-inputs,
.settings-personal-information_content-inputs_first-values {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.settings-personal-information_content-inputs_first-inputs {
  width: max-content;
}

.settings-personal-information_content-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}

.settings-personal-information_content-buttons_header {
  font-family: 'Roboto';
  font-size: 20px;
  line-height: 23px;
  color: #36434b;
  margin-bottom: 7px;
}

.settings-personal-information_content-buttons_button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-personal-information_content-buttons_button-white,
.settings-personal-information_content-buttons_button-orange {
  padding: 10px 0px;
  width: 166px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.161);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.4s;
  border: none;
  cursor: pointer;
}

.settings-personal-information_content-buttons_button-orange {
  background-color: #fea65a;
  margin-left: 20px;
}

.settings-personal-information_content-buttons_button-white:hover,
.settings-personal-information_content-buttons_button-orange:hover {
  opacity: 0.7;
}

.settings-personal-information_content-header>img {
  cursor: pointer;
  transition-duration: 0.4s;
}

.settings-personal-information_content-header>img:hover {
  opacity: 0.7;
}

.settings-personal-information_delete-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
}

.settings-personal-information_delete-modal_content {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
}

.action-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
  background-color: #e4f3fd;
}

.action-btn img {
  width: 16px;
}

.action-btn>button {
  display: none;
}

.settings-personal-information_delete-modal_content-headers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.settings-personal-information_delete-modal_content-headers>span {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 22px;
  line-height: 14px;
  color: #36434b;
}

.settings-personal-information_delete-modal_content-headers>img {
  cursor: pointer;
}

.settings-personal-information_delete-modal_content_inputs {
  display: flex;
  flex-direction: column;
}

.settings-personal-information_delete-modal_content_inputs>input {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #777777;
  padding: 16px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  margin-bottom: 14px;
}

.settings-personal-information_delete-modal_content_inputs_input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.settings-personal-information_delete-modal_content_inputs_input>input {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #777777;
  padding: 16px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  margin-bottom: 14px;
  width: calc(100% - 30px);
}

.settings-personal-information_delete-modal_content_remember {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
}

.settings-personal-information_delete-modal_content_remember>input {
  border: 1px solid #36434b;
  border-radius: 5px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.settings-personal-information_delete-modal_content_remember>span {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 14px;
  color: #36434b;
}

.settings-personal-information_delete-modal_content-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-personal-information_delete-modal_content-buttons_delete {
  text-decoration: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.161) !important;
  padding: 12px 0px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #36434b !important;
  width: 166px !important;
  display: flex !important;
  justify-content: center !important;
  transition-duration: 0.4s !important;
  text-transform: none !important;
}

.settings-personal-information_delete-modal_content-buttons_delete:hover,
.settings-personal-information_delete-modal_content-cancel:hover {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.161) !important;
}

.settings-personal-information_delete-modal_content-cancel {
  text-decoration: none;
  padding: 16px 24px;
  font-family: 'Minion Pro';
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  color: #ffffff;
  width: 166px;
  display: flex;
  justify-content: center;
  background: #fea65a;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.161);
  border-radius: 10px;
  margin-bottom: 30px;
}

.settings-personal-information_delete-modal_content_border {
  border: 1px solid #cdcdcd;
}

.settings-personal-information_delete-modal_content_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 20px;
}

.settings-personal-information_delete-modal_content_footer>span {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 22px;
  line-height: 18px;
  color: #36434b;
}

.settings-personal-information_delete-modal_content_footer>input {
  border: 1px solid #dadada;
  border-radius: 20px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.verification {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px !important;
  color: #fea65a;
  cursor: pointer;
}

@media screen and (min-width: 765px) {
  .settings-labels {
    font-size: 18px;
    width: 200px;
  }

  .user-info {
    font-size: 18px;
  }

  .action-btn>button {
    display: inline;
    border: none;
    background: none;
    color: #404d56;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
}