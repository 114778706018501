.spark-container {
  background: #fff;
  padding: 24px 16px;
  margin-bottom: 16px;
}

.spark-container-modal {
  margin-bottom: 16px;
}

.action-container {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: flex-start;
  margin-top: 4px;
  min-width: 50px;
}

.empty-grave-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  background: #fff;
  border-radius: 10px;
}

.empty-grave {
  text-align: center;
  max-width: 340px;
  font-size: 16px;
  color: #080809;
}

.avatar {
  width: 48px !important;
  height: 48px !important;
  align-self: flex-start;
  cursor: pointer !important;
}

.circle {
  width: 8px;
  height: 8px;
  background: hsl(120deg 60% 50%);
  border-radius: 50%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.category {
  align-self: flex-start;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  color: #080809;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
}

.user-header {
  display: flex;
  flex-direction: column;
  min-width: 100px;
}

.create-at {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  color: #777777;
}

.dot {
  height: 3px;
  width: 3px;
  background: #777777;
  border-radius: 25px;
}

.user-name-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.user-name-container-edit {
  flex-direction: column;
  display: flex;
  gap: 4px;
  align-items: flex-start;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-name {
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  text-align: left;
  color: #080809;
  cursor: pointer;
  transition: ease 0.3s;
  width: max-content;
  border-bottom: 1px solid transparent;
}

.user-name:hover {
  border-color: #080809;
}

.description {
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
  color: #080809;
  margin-bottom: 12px;
  word-break: break-word;
}

.loader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.leader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 24px 0px;
}

.loading {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  color: #fea65a;
}

@media screen and (min-width: 821px) {
  .user-header {
    display: flex;
    flex-direction: column;
    min-width: 200px;
  }

  .spark-container {
    border-radius: 10px;
  }
  .user-name {
    font-size: 18px;
  }
  .create-at {
    font-size: 14px;
  }

  .category {
    font-size: 14px;
  }
}
