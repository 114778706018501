.footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
  border-top: 1px solid #e1e1e1;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.graveLinkContainer {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.graveContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 300px;
}

.graveWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.graveAvatar {
  border: 6px solid #f5f5f5;
  width: 130px;
  height: 144px;
  margin-bottom: 4px;
  cursor: pointer;
}

.graveDates {
  text-align: center;
}

.graveName {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.graveRelation {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  color: #404d56;
  text-align: center;
}

.linkIconContainer {
  flex: 0.7;
  margin-top: 50px;
}

.linkIcon {
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.line {
  flex: 1;
  height: 3px;
  background-color: #404d56;
}

.circleDot {
  border: 3px solid #404d56;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.filledDot {
  border: 3px solid #404d56;
  background-color: #404d56;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .linkIconContainer {
    flex: 0.4;
  }
}

@media screen and (max-width: 768px) {
  .linkIconContainer {
    flex: 1;
    margin-top: -100px;
  }
  .graveLinkContainer {
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 10px;
  }
}
