.container {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}

.comment-container {
  margin-bottom: 8px;
  width: 100%;
}

.comment-outline {
  padding: 8px 12px;
  border-radius: 10px;
  background: #f5f5f5;
  margin-bottom: 8px;
}

.user-info {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.user-name {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.4;
  color: #080809;
  font-family: 'Roboto';
  border-bottom: 1px solid transparent;
  transition: all ease 0.3s;
  cursor: pointer;
}

.user-name:hover {
  border-color: #080809;
}

.description {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #080809;
  word-break: break-word;
  white-space: pre-wrap !important;
  overflow-wrap: anywhere;
}

.description a {
  word-break: break-word;
  overflow-wrap: anywhere;
}

.avatar {
  height: 36px !important;
  width: 36px !important;
}

.reply-avatar {
  height: 26px !important;
  width: 26px !important;
}
