.change-password {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.change-password_content {
  display: flex;
  flex-direction: column;
}

.change-password_content-header {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  margin-bottom: 32px;
}

.change-password_orange-line {
  width: 173px;
  border: 1px solid #fea65a;
  margin-bottom: 30px;
}

.change-password_content-input_input {
  border: none;
  outline: none;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  height: 60px;
  padding-left: 20px;
  background: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 20px;
  color: #777777;
}

.change-password_content-button {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 77px;
}

.change-password_content-button_save {
  font-family: 'Minion Pro';
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #fea65a;
  border-radius: 10px;
  height: 40px;
  width: 150px;
  transition-duration: 0.4s;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.change-password_content-button_save:disabled {
  background: #bdbdbd;
  cursor: auto;
}

.password-error-message {
  color: #fea67c;
  margin-bottom: 20px;
}

.password-error-message-subheading {
  color: #777777;
  margin-bottom: 20px;
}

.change-password_content-button_cancel {
  font-family: 'Minion Pro';
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 40px;
  width: 150px;
  color: #fea65a;
  border: 1px solid #fea65a;
  border-radius: 10px;
  transition-duration: 0.4s;
  margin-right: 30px;
  background: none;
  cursor: pointer;
}

@media (max-width: 750px) {
  /* .change-password {
    margin-top: -20px;
  } */

  /* .change-password_border {
  } */

  /* .change-password_content {
    padding: 20px 10px;
  } */

  .change-password_content-header {
    font-size: 24px;
    line-height: 23px;
  }

  .change-password_content-input_input {
    font-size: 12px;
    line-height: 14px;
  }

  .change-password_content-button {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .change-password_content-button_cancel,
  .change-password_content-button_save {
    font-size: 16px;
    line-height: 15px;
  }
}
