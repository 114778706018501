.grave-avatar-container {
  position: relative;
  cursor: pointer;
  margin-top: -90px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
  border-top: 1px solid #e1e1e1;
}

.cropperWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .grave-avatar-container {
    margin-top: -52px;
  }
}
