.inputButton {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 20px;
  cursor: pointer;
  min-height: 40px;
  padding: 12px;
  text-align: start;
  transition: all 0.3s ease;
  width: 100%;
}

.inputButton:hover {
  background-color: #f5f5f5;
}

.memorySection {
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 12px;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .memorySection {
    border-radius: 0px;
    box-shadow: none;
  }
}
