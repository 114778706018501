.container {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 6px;
}

.heading {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 29.3px;
  color: #36434b;
}

.btn-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.btn-container > button {
  font-family: 'Roboto' Condensed;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.44px;
  color: #404d56;
  border: none;
  background: transparent;
  cursor: pointer;
}
