.invitations-names {
  margin-bottom: 12px;
}

.invitations-names-form {
  margin-bottom: 42px;
}

.subscription-status {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
  text-align: center;
}

.add-button {
  border-radius: 10px;
  border: 1px solid #fea65a;
  background: #fff;
  padding: 14px;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}

.search-input {
  display: flex;
  margin-bottom: 24px;
}

.inputs {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 17px 13px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
  flex: 1;
}

.grave-btn-container,
.grave-actions-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.grave-information-edit-delete-container {
  display: flex;
  gap: 24px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.action-btn>button {
  border: none;
  background: none;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #fea65a;
  background: #fff;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.rootDiscard:disabled {
  background: #cbcbcb !important;
  cursor: not-allowed;
}

.empty-invitation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.empty-invitation-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.empty-invitation-wrapper>span {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 34.5px;
}

.empty-invitation-wrapper>button {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 700;
  line-height: 34.5px;
  border: none;
  cursor: pointer;
  background: #fff;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 32px;
}

.heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  word-break: break-word;
  max-width: 100%;
}

@media screen and (max-width: 750px) {
  .heading {
    font-size: 24px;
  }
}

.sub_heading {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
}

.plan-container {
  border-radius: 20px;
  background: #ddffde;
  margin-bottom: 32px;
  max-width: max-content;
  padding: 10px 12px;
}

.plan-heading {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
}

.plan-link {
  text-decoration: none;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 47.5px;
}

.zero-invitation {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 24px 20px;
  border-radius: 20px;
  background: #ddffde;
  max-width: max-content;
}

.zero-invitation-message {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}

.btn-invitation {
  border-radius: 8px;
  background: #985000;
  border: none;
  cursor: pointer;
  padding: 15px 17px;
  color: #fff;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-bottom: 42px;
}

.invitations-list {
  margin-bottom: 24;
  border: 1px solid rgb(221 225 229);
  border-radius: 10px;
  padding: 24;
}

@media (max-width: 800px) {
  .invitations {
    width: 100%;
    border-radius: 0px;
    border-left: none;
  }

  .grave-btn-container {
    display: inline;
  }

  .grave-information_content-verify {
    flex-direction: column;
  }

  .invitations-list {
    padding: 16px;
  }
}