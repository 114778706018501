.imageContainer {
  position: relative;
  width: 100%;
  height: 230px;
  overflow: hidden;
  border-radius: 4px;
}

.topBar {
  position: absolute;
  top: 0;
  width: calc(100% - 24px);
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.coverPhoto {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover; /* Adjust to fill the container */
  background-position: center;
  border-radius: 0px 0px 10px 10px;
  background-color: #ccc;
}

.coverPhotoWrapper {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 0px 0px 10px 10px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .coverPhotoWrapper {
    height: 250px;
    border-radius: 0px;
  }

  .coverPhoto {
    border-radius: 0px;
  }
}
