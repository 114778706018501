.deceased-header-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.header-deceased-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.relations-title {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
}

.about-container {
  display: flex;
  gap: 24px;
}

.heading-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.heading {
  color: #19262d;
  /* font-family: 'Roboto'; */
  font-size: 38px;
  font-weight: 700;
  hyphens: auto;
  -webkit-hyphens: auto; /* For Safari */
  -moz-hyphens: auto; /* For Firefox */
  -ms-hyphens: auto; /* For Internet Explorer */
  word-wrap: break-word;
}

.deceased-grave-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.death-age {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
}

.date-of-birth,
.date-of-death {
  margin-left: 8px;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
}

.side-border {
  width: 2px;
  height: 16px;
  background: rgba(0, 0, 0, 0.2);
}

.reason-of-death {
  color: #96a3ad;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
}

.add-invites {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}

.relationships-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.claim-grave-button {
  color: #008905;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 170%;
  border: none;
  border-radius: 10px;
  background: #d8ffe5;
  padding: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.join-grave-button {
  color: #fff;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 170%;
  border: none;
  border-radius: 10px;
  background: #404d56;
  padding: 10px;
  cursor: pointer;
}

.header-right-container-button-wrapper {
  margin-bottom: 15px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.waiting-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.set-relation-green {
  color: #008905;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  padding: 10px;
  border-radius: 10px;
  background: #d8ffe5;
  margin-bottom: 12px;
}

.conformation-days {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
}

.grave-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .grave-avatar {
    gap: 24px;
    justify-content: unset;
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .deceased-header-container {
    flex-direction: column;
    align-items: center;
  }
  .header-deceased-info {
    align-items: center;
    gap: 8px;
  }
  .about-container {
    gap: 12px;
    flex-direction: column;
  }
  .deceased-grave-info {
    flex-direction: column;
    gap: 8px;
  }
  .heading {
    text-align: center;
    font-size: 24px;
  }
  .reason-of-death {
    text-align: center;
  }
  .heading-container {
    align-items: center;
    gap: 8px;
  }
}

@media screen and (max-width: 1020px) {
  .side-border {
    display: none;
  }
}
