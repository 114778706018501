.user-connections-container {
  background-color: #fff;
  padding: 12px;
  min-width: 340px;
  height: max-content;
  min-height: 230px;
}

.user-connections_item {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  flex-direction: column;
}

.user-connections_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 16px;
}

.user-connections-header {
  width: 100%;
}

.user-connections-header_title>span {
  height: 15px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 18px;
  color: #36434b;
}

.user_name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #36434b;
}

.user_info {
  text-align: center;
  display: grid;
}

.view-all {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  color: #fea65a;
  padding-left: 24px;
  margin-top: 12px;
  cursor: pointer;
  transition: ease 0.3s;
}

.view-all:hover {
  color: #985000;
}

.avatar {
  height: 70px !important;
  width: 70px !important;
  border: 1px solid lightgrey !important
}

.user_relation {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #777777;
}

.user-connections-container>h1 {
  text-align: center;
  font-size: 20px;
  color: #fea65a;
  margin-top: 66px;
  display: flex;
}

.user-connections-header_title {
  display: flex;
  height: 50px;
  align-items: center;
  padding: 0px 6px;
  margin-bottom: 24px;
}

.empty-grave-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.empty-grave {
  text-align: center;
  max-width: 340px;
  font-size: 16px;
  color: #36434b;
}

@media screen and (min-width: 821px) {
  .user-connections-container {
    border-radius: 10px;
  }
}