.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

h3 {
  margin: 0;
}

.editButton {
  color: #404d56;
}

.editButton:hover {
  color: #36434b;
}

.graveLinkContainer {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.graveContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  max-width: 136px;
}

.graveAvatar {
  border: 6px solid #f5f5f5;
  width: 130px;
  height: 144px;
  margin-bottom: 4px;
  cursor: pointer;
}

.graveDates {
  text-align: center;
}

.graveName {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.graveRelation {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  color: #404d56;
  text-align: center;
}

.linkIconContainer {
  flex: 0.7;
  margin-top: 50px;
}

.linkIcon {
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.line {
  flex: 1;
  height: 3px;
  background-color: #404d56;
}

.circleDot {
  border: 3px solid #404d56;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.filledDot {
  border: 3px solid #404d56;
  background-color: #404d56;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .linkIconContainer {
    flex: 0.4;
  }
}

@media screen and (max-width: 480px) {
  .graveAvatar {
    width: 106px;
  }
  .graveContainer {
    max-width: 100px;
    padding: 0px 8px;
  }
  .linkIconContainer {
    flex: 1;
  }
  .graveLinkContainer {
    justify-content: space-between;
    gap: 4px;
  }
}
